// import { useGLTF, useAnimations } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { useViewStore } from '../store/useViewStore'

const viewsDict = { introTwo: 'Intro02', layerOne: 'Layer01', layerTwo: 'Layer02', layerThree: 'Layer03' }
// const views = ['Intro01', 'Intro02', 'Layer01', 'Layer02', 'Layer03']
const views = ['View01', 'View02', 'View03', 'View04', 'View05', 'View06', 'View07', 'View08']
// const durationDict = [3.5, 2.33, 1, 1.03, 1, 1, 1.03, 1]
const viewClips = []
export default function useTransitions(actions, names, durations) {
    // const { scene, animations } = useGLTF('/Demo01_Animation_v2.glb')
    // console.log(nodes.Scene)
    // const { actions, mixer, names } = useAnimations(animations, scene)
    // const [currentView, setView] = useState(0)
    const currentView = useViewStore((state) => state.view)
    console.log(currentView)
    const setView = useViewStore((state) => state.setView)
    const previousView = useRef(null)
    const handleKeyDown = (e) => {
        const max = views.length - 1
        if ('ArrowRight' === e.key) {
            setView((v) => {
                // playClips(v)
                return v < max ? v + 1 : v
            })
        }
        if ('ArrowLeft' === e.key) {
            setView((view) => {
                // playClips(view)
                return view > 0 ? view - 1 : 0
            })
        }
    }
    useEffect(() => {
        // console.log('view', view, previousView)
        if (previousView.current === null) {
            previousView.current = 0
            return
        }
        if (currentView > previousView.current) {
            playClips(currentView)
        }
        if (currentView < previousView.current) {
            playClips(previousView.current, true)
        }
        previousView.current = currentView
    }, [currentView])
    useEffect(() => {
        let listener = document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [])
    useEffect(() => {
        Object.values(actions).forEach((action) => {
            action.clampWhenFinished = true
            action.repetitions = 1
        })
        views.map((view) => {
            const matches = names.filter((name) => name.startsWith(view))
            let viewActions = matches.map((match) => actions[match])
            viewActions.forEach((action, index) => {
                const clip = action.getClip()
                clip.duration = durations[index]
                clip.trim()
                clip.resetDuration()
                // clip.trim()
            })
            viewClips.push(viewActions)
        })
        // viewClips[1].forEach((clip, index) => {
        //     clip.getClip().duration = 2.33
        //     clip.getClip().trim()
        // })
    }, [])
    const playClips = (view, reverse = false) => {
        viewClips[view].forEach((clip) => {
            if (reverse) {
                clip.timeScale = -2
            } else {
                clip.timeScale = 1
            }
            clip.reset().play()
        })
    }
    const doTransition = (view, reverse = false) => {
        if (view === currentView && !reverse) {
            return
        }
        viewClips[view].forEach((clip) => {
            if (reverse) {
                clip.timeScale = -2
            } else {
                clip.timeScale = 1
            }
            clip.reset().play()
        })
        if (reverse) {
            setView(view - 1)
        } else {
            setView(view)
        }
    }
    return [currentView, doTransition]
}
// useGLTF.preload('/Demo01_Animation_v2.glb')
