/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 ../public/Demo01_Animation_v7b.glb -ks 
*/

import React, { forwardRef, useRef, useState } from 'react'
import { useGLTF, useAnimations, Environment, Html } from '@react-three/drei'
import useTransitions from './hooks/useTransitions'
import { useStore, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import gsap from 'gsap'
import { onHoverOver, onHoverOut } from './event'
import VideoPlayer from './components/VideoPlayer'

export const Model = forwardRef(function Model(props, ref) {
    const store = useStore()
    const scene = useThree().scene
    const [open, setOpen] = useState(false)
    // const group = useRef()
    const { nodes, materials, animations } = useGLTF('/Demo01_Animation_v8.glb')
    const { actions, names } = useAnimations(animations, ref)
    const durations = [3.5, 2.33, 1, 1.03, 1, 1, 1.03, 1]
    const [view, setView] = useTransitions(actions, names, durations)
    return (
        <group ref={ref} {...props} dispose={null}>
            <group name="Scene">
                <group name="____CAMERAS____" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <group name="Cameras" position={[57.817, 12.667, -18.046]} />
                </group>
                <Environment>
                    <group name="____LIGHTS____" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                        <mesh
                            name="Back_Light"
                            // castShadow
                            // receiveShadow
                            geometry={nodes.Back_Light.geometry}
                            material={materials.Lights}
                            position={[22.008, -2308.323, 431.642]}
                        />
                        <mesh
                            name="Left_Light"
                            // castShadow
                            // receiveShadow
                            geometry={nodes.Left_Light.geometry}
                            material={materials.Lights}
                            position={[-1997.785, 0, 246.654]}
                        />
                        <mesh
                            name="Right_Light"
                            // castShadow
                            // receiveShadow
                            geometry={nodes.Right_Light.geometry}
                            material={materials.Lights}
                            position={[2066.7, 896.65, 246.654]}
                        />
                        <mesh
                            name="Top_Light"
                            // castShadow
                            // receiveShadow
                            geometry={nodes.Top_Light.geometry}
                            material={materials.Lights}
                            position={[811.804, 740.269, -1299.715]}
                        />
                    </group>
                </Environment>
                <group name="____OBJECTS____" position={[0, -0.047, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
                    <group name="Part_1___Building" position={[29.506, 17.576, -3.767]}>
                        <group name="Building" position={[-25.835, -18.036, -10.262]}>
                            <group
                                name="Main_Building"
                                position={[-7.769, -18.295, 3.084]}
                                rotation={[0, 0, -Math.PI]}
                                scale={0.1}
                                onClick={() => {
                                    view == 2
                                    setView(1, true)
                                }}
                            >
                                <mesh
                                    name="Building_White"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Building_White.geometry}
                                    material={materials.White}
                                    position={[-28.325, -155.925, -1354.033]}
                                    rotation={[0, 0, Math.PI]}
                                    scale={10}
                                />
                                <mesh
                                    name="Building_Windows"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Building_Windows.geometry}
                                    material={materials.Window}
                                    position={[-70.208, -131.4, -1225.014]}
                                />
                                <mesh
                                    name="Business_Strategy"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Business_Strategy.geometry}
                                    material={materials.Text01}
                                    position={[15.813, -485.078, -2437.264]}
                                    rotation={[0, 0, Math.PI]}
                                    scale={10}
                                />
                                <mesh
                                    name="Entrance_Window"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Entrance_Window.geometry}
                                    material={materials.Window}
                                    position={[-75.296, -711.721, -144.315]}
                                    rotation={[1.571, -1.569, -1.571]}
                                    scale={60}
                                />
                            </group>
                            <group name="Text" position={[-2.727, 0.342, 18.61]} rotation={[-Math.PI / 2, 0, 0]}>
                                <group
                                    name="View02_Customers"
                                    position={[-0.324, -0.284, 1.377]}
                                    rotation={[0, 1.23, 0]}
                                >
                                    <mesh
                                        name="Text_Customers"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Text_Customers.geometry}
                                        material={materials.Ground}
                                        position={[-179.751, 6.098, 133.428]}
                                        rotation={[Math.PI / 2, 0, 1.23]}
                                    />
                                    <mesh
                                        name="Text_Platform_3"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Text_Platform_3.geometry}
                                        material={materials.Layer02}
                                        position={[7.325, 2.184, -0.007]}
                                        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                                    />
                                </group>
                                <group name="View02_People" position={[0.604, -0.284, -0.85]} rotation={[0, -0.31, 0]}>
                                    <mesh
                                        name="Text_People"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Text_People.geometry}
                                        material={materials.Ground}
                                        position={[-187.843, 6.098, 126.947]}
                                        rotation={[Math.PI / 2, 0, -0.31]}
                                    />
                                    <mesh
                                        name="Text_Platform"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Text_Platform.geometry}
                                        material={materials.Layer04}
                                        position={[0.012, 2.184, -4.929]}
                                        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                                    />
                                </group>
                                <group name="View02_Products" position={[2.71, -0.284, 1.28]} rotation={[0, 0.458, 0]}>
                                    <mesh
                                        name="Text_Platform_2"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Text_Platform_2.geometry}
                                        material={materials.Layer03}
                                        position={[2.479, 2.184, -6.378]}
                                        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
                                    />
                                    <mesh
                                        name="Text_Products___Services"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Text_Products___Services.geometry}
                                        material={materials.Ground}
                                        position={[-182.893, 6.098, 127.706]}
                                        rotation={[Math.PI / 2, 0, 0.458]}
                                    />
                                </group>
                            </group>
                            <group name="Vehicles" position={[-0.018, 8.242, 1.427]} rotation={[0, 0, -Math.PI / 2]}>
                                <group
                                    name="Car_med_1"
                                    position={[-89.652, -55.332, 8.106]}
                                    rotation={[0, 0, Math.PI / 2]}
                                >
                                    <mesh
                                        name="Mesh026"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh026.geometry}
                                        material={materials['Carpaint 001 MediumBlue 25cm']}
                                    />
                                    <mesh
                                        name="Mesh026_1"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh026_1.geometry}
                                        material={materials.White}
                                    />
                                    <mesh
                                        name="Mesh026_2"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh026_2.geometry}
                                        material={materials['Vehicle Tire']}
                                    />
                                </group>
                                <group
                                    name="Car_small_1"
                                    position={[-84.314, 73.681, 7.172]}
                                    rotation={[0, 0, Math.PI / 2]}
                                >
                                    <mesh
                                        name="Mesh025"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh025.geometry}
                                        material={materials['Carpaint 001 MediumBlue 25cm']}
                                    />
                                    <mesh
                                        name="Mesh025_1"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh025_1.geometry}
                                        material={materials['Vehicle Tire']}
                                    />
                                    <mesh
                                        name="Mesh025_2"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh025_2.geometry}
                                        material={materials.White}
                                    />
                                </group>
                                <group
                                    name="Car_Sport"
                                    position={[-89.306, 49.515, 3.614]}
                                    rotation={[0, 0, Math.PI / 2]}
                                >
                                    <mesh
                                        name="Mesh024"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh024.geometry}
                                        material={materials.White}
                                    />
                                    <mesh
                                        name="Mesh024_1"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh024_1.geometry}
                                        material={materials['Vehicle Tire']}
                                    />
                                    <mesh
                                        name="Mesh024_2"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh024_2.geometry}
                                        material={materials['Carpaint 001 MediumBlue 25cm']}
                                    />
                                </group>
                                <group name="Truck" position={[-0.494, -113.093, 4.83]} rotation={[0, 0, Math.PI / 2]}>
                                    <mesh
                                        name="Mesh027"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh027.geometry}
                                        material={materials['Carpaint 001 MediumBlue 25cm']}
                                    />
                                    <mesh
                                        name="Mesh027_1"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh027_1.geometry}
                                        material={materials.White}
                                    />
                                    <mesh
                                        name="Mesh027_2"
                                        castShadow
                                        receiveShadow
                                        geometry={nodes.Mesh027_2.geometry}
                                        material={materials['Vehicle Tire']}
                                    />
                                </group>
                            </group>
                            <mesh
                                name="Building_Base_Cylinder"
                                castShadow
                                receiveShadow
                                geometry={nodes.Building_Base_Cylinder.geometry}
                                material={materials.Ground}
                                position={[-0.61, -5.197, 16.998]}
                                rotation={[0, 0, -Math.PI / 2]}
                            />
                        </group>
                        <group name="Wireframe_Buildings" position={[-29.506, -17.576, 14.008]}>
                            <mesh
                                name="View02_Wireframe_Building"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building.geometry}
                                material={materials['Wireframe Black']}
                                position={[-256.411, -204.923, -5.702]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_2"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_2.geometry}
                                material={materials['Wireframe Black']}
                                position={[361.978, -214.757, -5.449]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_3"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_3.geometry}
                                material={materials['Wireframe Black']}
                                position={[-77.207, -516.574, -5.226]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_4"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_4.geometry}
                                material={materials['Wireframe Black']}
                                position={[-393.552, -71.658, -5.114]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_5"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_5.geometry}
                                material={materials['Wireframe Black']}
                                position={[-281.802, -562.88, -5.947]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_6"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_6.geometry}
                                material={materials['Wireframe Black']}
                                position={[612.965, -537.849, -5.145]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_7"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_7.geometry}
                                material={materials['Wireframe Black']}
                                position={[437.065, -1.557, -6.133]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_8"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_8.geometry}
                                material={materials['Wireframe Black']}
                                position={[11.701, -302.681, -5.448]}
                            />
                            <mesh
                                name="View02_Wireframe_Building_9"
                                castShadow
                                receiveShadow
                                geometry={nodes.View02_Wireframe_Building_9.geometry}
                                material={materials['Wireframe Black']}
                                position={[412.055, -669.715, -4.978]}
                            />
                        </group>
                        <mesh
                            name="View02_Ground_Plane"
                            // castShadow
                            receiveShadow
                            geometry={nodes.View02_Ground_Plane.geometry}
                            material={materials.Ground}
                            position={[-29.506, -17.576, 9.444]}
                            scale={0.8}
                        />
                    </group>
                    <group name="Part_2___Structure" position={[29.506, 17.576, -3.767]}>
                        <group name="Disc_Layers" position={[-29.506, -17.576, -0.885]}>
                            <group
                                name="View02_Apps___Data"
                                position={[2.908, -5.881, 36.03]}
                                rotation={[0, 0, Math.PI]}
                            >
                                <group
                                    name="Mini_Disc_Platforms"
                                    position={[-2.908, 5.881, -14.561]}
                                    rotation={[0, 0, 1.536]}
                                >
                                    <group
                                        name="View04_Siloed_Disc"
                                        position={[0, -393, 0]}
                                        scale={1.5}
                                        onClick={() => {
                                            view == 2 &&
                                                onHoverOut(['siloed-hover-light', 'Siloed_Data_Mouseover_Off'], scene)
                                            setView(3)
                                        }}
                                        // onPointerOver={(e) => {
                                        //     scene
                                        //         .getObjectByName('siloed-hover-light')
                                        //         .material.color.set(new THREE.Color('red'))
                                        //     console.log(scene.getObjectByName('siloed-hover-light'))
                                        // }}
                                        onPointerEnter={(event) => {
                                            view == 2 &&
                                                onHoverOver(['siloed-hover-light', 'Siloed_Data_Mouseover_Off'], scene)
                                        }}
                                        onPointerLeave={(event) => {
                                            view != 3 &&
                                                onHoverOut(['siloed-hover-light', 'Siloed_Data_Mouseover_Off'], scene)
                                        }}
                                    >
                                        <group
                                            name="View03_Siloed_Text"
                                            position={[3.421, 3.542, -42.521]}
                                            rotation={[0, 0, -1.536]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="click_target_siloeddata"
                                                castShadow
                                                receiveShadow
                                                visible={false}
                                                geometry={nodes.click_target_siloeddata.geometry}
                                                material={nodes.click_target_siloeddata.material}
                                                position={[5.525, -5.242, -0.439]}
                                                rotation={[Math.PI / 2, -0.803, 0]}
                                            />
                                            <mesh
                                                name="Siloed_Data_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Siloed_Data_Mouseover_Off.geometry}
                                                // material={materials['Text Off']}
                                                position={[0, 0, 8.177]}
                                            >
                                                <meshStandardMaterial {...materials['Text Off']} />
                                            </mesh>
                                        </group>
                                        <group
                                            name="Environment"
                                            position={[-15.037, 8.948, -1.996]}
                                            rotation={[-1.571, 1.536, 0]}
                                            scale={0.667}
                                        >
                                            <group name="Touch_Points" position={[14.201, -23.973, 22.073]}>
                                                <group
                                                    name="View04_Kiosk01"
                                                    position={[-36.433, 22.527, 44.562]}
                                                    rotation={[Math.PI / 2, 0, -1.195]}
                                                    scale={0.01}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setView(4)
                                                        onHoverOut(['Body_Mouseover_Off_2'], e.eventObject, '#d4e7ed')
                                                        setTimeout(
                                                            () =>
                                                                document
                                                                    .querySelector('.ui-screen')
                                                                    .classList.add('visible'),
                                                            200
                                                        )
                                                    }}
                                                    onPointerEnter={(e) => {
                                                        if (view != 3) return
                                                        e.stopPropagation()
                                                        const color =
                                                            e.eventObject.getObjectByName('Body_Mouseover_Off_2')
                                                                .material.color
                                                        console.log(color.getHexString())
                                                        onHoverOver(['Body_Mouseover_Off_2'], e.eventObject)
                                                    }}
                                                    onPointerLeave={(e) => {
                                                        e.stopPropagation()
                                                        // const color =
                                                        //     e.eventObject.getObjectByName('Body_Mouseover_Off_2')
                                                        //         .material.color
                                                        onHoverOut(['Body_Mouseover_Off_2'], e.eventObject, '#d4e7ed')
                                                    }}
                                                >
                                                    <mesh
                                                        name="View05_Use_Case___Prototyping"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.View05_Use_Case___Prototyping.geometry}
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.384, 0.771, -9.029]}
                                                        rotation={[0, 0, 1.195]}
                                                        scale={0.588}
                                                    />
                                                    <group name="Kiosk" position={[0, 0.002, 0]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_2"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_2.geometry}
                                                            // material={materials['Kiosk Off Fill']}
                                                            position={[0, 0.002, -9.252]}
                                                        >
                                                            <meshStandardMaterial {...materials['Kiosk Off Fill']} />
                                                        </mesh>
                                                        <mesh
                                                            name="Wireframe_Outline_2"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_2.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0, 0.002, 0.133]}
                                                            rotation={[0, 0, 1.195]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                </group>
                                                <group
                                                    name="View04_Kiosk02"
                                                    position={[-20.707, 22.527, 18.699]}
                                                    rotation={[Math.PI / 2, 0, -0.863]}
                                                    scale={0.01}
                                                >
                                                    <group name="Kiosk_2" position={[0, -0.001, 0]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_3"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_3.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[0, -0.001, -9.252]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_3"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_3.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0, -0.001, 0.133]}
                                                            rotation={[0, 0, 0.863]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                    <mesh
                                                        name="Data_Warehouse_Data_Lake"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Data_Warehouse_Data_Lake.geometry}
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.448, 0.769, -8.675]}
                                                        rotation={[0, 0, 0.863]}
                                                        scale={0.588}
                                                    />
                                                </group>
                                                <group
                                                    name="View04_Kiosk03"
                                                    position={[28.036, 22.527, -1.542]}
                                                    rotation={[Math.PI / 2, 0, 0.087]}
                                                    scale={0.01}
                                                >
                                                    <group name="Kiosk_3" position={[0.004, 0, 0]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_4"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_4.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[0.004, 0, -9.252]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_4"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_4.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0.004, 0, 0.133]}
                                                            rotation={[0, 0, -0.087]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                    <mesh
                                                        name="AI_ML"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.AI_ML.geometry}
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.303, 0.768, -8.944]}
                                                        rotation={[0, 0, -0.087]}
                                                        scale={0.588}
                                                    />
                                                </group>
                                                <group
                                                    name="View04_Kiosk04"
                                                    position={[57.223, 22.527, 5.681]}
                                                    rotation={[Math.PI / 2, 0, 0.419]}
                                                    scale={0.01}
                                                >
                                                    <group name="Kiosk_4" position={[0.002, -0.003, 0]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_5"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_5.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[0.002, -0.003, -9.252]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_5"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_5.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0.002, -0.003, 0.133]}
                                                            rotation={[0, 0, -0.419]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                    <mesh
                                                        name="Visualization___Optimization"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Visualization___Optimization.geometry}
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.334, 0.765, -9.029]}
                                                        rotation={[0, 0, -0.419]}
                                                        scale={0.588}
                                                    />
                                                </group>
                                                <group
                                                    name="View04_Video_Player"
                                                    position={[7.524, 48.861, 17.149]}
                                                    rotation={[1.528, 0.019, -0.414]}
                                                    scale={0.01}
                                                >
                                                    <mesh
                                                        name="Body_Mouseover_Off"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Body_Mouseover_Off.geometry}
                                                        material={materials['Kiosk Off Fill']}
                                                        position={[0.001, 0.001, 0.162]}
                                                    />
                                                    <mesh
                                                        name="Play_Button"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Play_Button.geometry}
                                                        material={materials['Play Button']}
                                                        position={[-0.001, 0.707, 0.162]}
                                                        rotation={[Math.PI / 2, 0, 0]}
                                                    />
                                                    <mesh
                                                        name="Wireframe_Outline"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Wireframe_Outline.geometry}
                                                        material={materials['Wireframe Kiosks']}
                                                        position={[0.002, 0.001, 9.679]}
                                                    />
                                                </group>
                                            </group>
                                        </group>
                                        <group name="Mini_Platform_3">
                                            <mesh
                                                name="Mesh082"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh082.geometry}
                                                material={materials['Layer01 Disc']}
                                            />
                                            <mesh
                                                name="Mesh082_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh082_1.geometry}
                                                material={materials.Layer01}
                                            />
                                        </group>
                                    </group>
                                    <group name="Inability_to_Scale" position={[277.893, -277.893, -0.042]}>
                                        <group
                                            name="View03_Inability_Text"
                                            position={[4.997, 5.174, -63.259]}
                                            rotation={[0, 0, -1.536]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="Inability_to_Scale_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Inability_to_Scale_Mouseover_Off.geometry}
                                                material={materials['Text Off']}
                                                position={[-0.003, -0.003, 7.696]}
                                            />
                                        </group>
                                        <group
                                            name="Mini_Platform_2"
                                            position={[0, 0, 0.042]}
                                            rotation={[0, 0, Math.PI / 4]}
                                            scale={1.5}
                                        >
                                            <mesh
                                                name="Mesh063"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh063.geometry}
                                                material={materials['Layer01 Disc']}
                                            />
                                            <mesh
                                                name="Mesh063_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh063_1.geometry}
                                                material={materials.Layer01}
                                            />
                                        </group>
                                    </group>
                                    <group name="Inflexable_Legacy_Apps" position={[393, 0, 0.757]}>
                                        <group
                                            name="View03_Inflexible_Text"
                                            position={[4.28, 4.432, -63.095]}
                                            rotation={[0, 0, -1.536]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="Inflexable_Legacy_Apps_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Inflexable_Legacy_Apps_Mouseover_Off.geometry}
                                                material={materials['Text Off']}
                                                position={[0, 0, 6.734]}
                                            />
                                        </group>
                                        <group
                                            name="Mini_Platform"
                                            position={[0, 0, -0.757]}
                                            rotation={[0, 0, Math.PI / 2]}
                                            scale={1.5}
                                        >
                                            <mesh
                                                name="Mesh060"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh060.geometry}
                                                material={materials['Layer01 Disc']}
                                            />
                                            <mesh
                                                name="Mesh060_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh060_1.geometry}
                                                material={materials.Layer01}
                                            />
                                        </group>
                                    </group>
                                    <group name="Mouseover_Light" position={[393, 0, 0]} rotation={[0, 0, -1.536]}>
                                        <mesh
                                            name="Mouseover_Light_Off"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group
                                        name="Mouseover_Light_2"
                                        position={[277.893, -277.893, 0]}
                                        rotation={[0, 0, -1.536]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_2"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_2.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group name="Mouseover_Light_3" position={[0, -393, 0]} rotation={[0, 0, -1.536]}>
                                        <mesh
                                            name="siloed-hover-light"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_3.geometry}
                                            // material={materials['MO Light Off']}
                                        >
                                            <meshStandardMaterial {...materials['MO Light Off']} />
                                        </mesh>
                                    </group>
                                </group>
                                <mesh
                                    name="Main_Disc"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Main_Disc.geometry}
                                    material={materials.Layer01}
                                    position={[-0.007, -0.004, 1.07]}
                                    rotation={[0, 0, 1.937]}
                                />
                            </group>
                            <group name="View02_Devices" position={[2.908, -5.881, 129.314]} rotation={[0, 0, Math.PI]}>
                                <group
                                    name="Mini_Disc_Platforms_4"
                                    position={[-2.908, 5.881, -12.215]}
                                    rotation={[0, 0, 1.728]}
                                >
                                    <group name="Mouseover_Light_12" position={[0, -393, 0]} rotation={[0, 0, -1.728]}>
                                        <mesh
                                            name="Mouseover_Light_Off_12"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_12.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group
                                        name="Mouseover_Light_13"
                                        position={[363.085, -150.395, 0]}
                                        rotation={[0, 0, -1.728]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_13"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_13.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group name="Mini_Platform_12" position={[0, -393, 0]} scale={1.5}>
                                        <mesh
                                            name="Mesh124"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh124.geometry}
                                            material={materials['Layer04 Disc']}
                                        />
                                        <mesh
                                            name="Mesh124_1"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh124_1.geometry}
                                            material={materials.Layer04}
                                        />
                                    </group>
                                    <group
                                        name="Mini_Platform_13"
                                        position={[363.085, -150.395, 0]}
                                        rotation={[0, 0, 1.178]}
                                        scale={1.5}
                                    >
                                        <mesh
                                            name="Mesh126"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh126.geometry}
                                            material={materials['Layer04 Disc']}
                                        />
                                        <mesh
                                            name="Mesh126_1"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh126_1.geometry}
                                            material={materials.Layer04}
                                        />
                                    </group>
                                </group>
                                <mesh
                                    name="Main_Disc_4"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Main_Disc_4.geometry}
                                    material={materials.Layer04}
                                    position={[-0.007, -0.004, 3.415]}
                                    rotation={[0, 0, 1.937]}
                                />
                            </group>
                            <group
                                name="View02_Infrastructure"
                                position={[2.908, -5.881, 72.427]}
                                rotation={[0, 0, Math.PI]}
                            >
                                <group
                                    name="Mini_Disc_Platforms_2"
                                    position={[-2.908, 5.881, -19.252]}
                                    rotation={[0, 0, 1.536]}
                                >
                                    <group
                                        name="View07_Limited_Scale_Disc"
                                        position={[154.612, -366.684, 0]}
                                        scale={1.5}
                                        onClick={() => {
                                            onHoverOut(
                                                ['limited-hover-light', 'Limited_Scale___Flexibility_Mouseover_Off'],
                                                scene
                                            )
                                            setView(6)
                                        }}
                                        onPointerEnter={(event) => {
                                            view == 5 &&
                                                onHoverOver(
                                                    [
                                                        'limited-hover-light',
                                                        'Limited_Scale___Flexibility_Mouseover_Off',
                                                    ],
                                                    scene
                                                )
                                        }}
                                        onPointerLeave={(event) => {
                                            view == 5 &&
                                                onHoverOut(
                                                    [
                                                        'limited-hover-light',
                                                        'Limited_Scale___Flexibility_Mouseover_Off',
                                                    ],
                                                    scene
                                                )
                                        }}
                                    >
                                        <group
                                            name="View06_Limited_Scale_Text"
                                            position={[3.002, 4.132, -49.191]}
                                            rotation={[0, 0, -1.379]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="click_target_limitedscale"
                                                castShadow
                                                receiveShadow
                                                visible={false}
                                                geometry={nodes.click_target_limitedscale.geometry}
                                                material={nodes.click_target_limitedscale.material}
                                                position={[5.113, -5.324, 0.725]}
                                                rotation={[Math.PI / 2, -0.82, 0]}
                                            />
                                            <mesh
                                                name="Limited_Scale___Flexibility_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Limited_Scale___Flexibility_Mouseover_Off.geometry}
                                                material={materials['Text Off']}
                                                position={[0, 0, 7.165]}
                                            >
                                                <meshStandardMaterial {...materials['Text Off']} />
                                            </mesh>
                                        </group>
                                        <group
                                            name="Environment_2"
                                            position={[-15.037, 8.948, -1.996]}
                                            rotation={[-1.571, 1.536, 0]}
                                            scale={0.667}
                                        >
                                            <group name="Touch_Points_2" position={[14.201, -23.973, 22.073]}>
                                                <group
                                                    name="View07_Kiosk01"
                                                    position={[-36.433, 22.527, 44.562]}
                                                    rotation={[Math.PI / 2, 0, -1.195]}
                                                    scale={0.01}
                                                >
                                                    <group name="Kiosk_5" position={[0.002, 0.002, 0.001]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_7"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_7.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[0.002, 0.002, -9.251]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_7"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_7.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0.002, 0.002, 0.133]}
                                                            rotation={[0, 0, 1.352]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                    <mesh
                                                        name="Define_Business_Goals"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Define_Business_Goals.geometry}
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.296, 0.77, -9.029]}
                                                        rotation={[0, 0, 1.352]}
                                                        scale={0.588}
                                                    />
                                                </group>
                                                <group
                                                    name="View07_Kiosk02"
                                                    position={[-20.707, 22.527, 18.699]}
                                                    rotation={[Math.PI / 2, 0, -0.863]}
                                                    scale={0.01}
                                                >
                                                    <group name="Kiosk_6">
                                                        <mesh
                                                            name="Body_Mouseover_Off_8"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_8.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[0, 0, -9.252]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_8"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_8.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0, 0, 0.133]}
                                                            rotation={[0, 0, 1.02]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                    <mesh
                                                        name="Evaluate_Application_Portfolio"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Evaluate_Application_Portfolio.geometry}
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.321, 0.767, -9.025]}
                                                        rotation={[0, 0, 1.02]}
                                                        scale={0.588}
                                                    />
                                                </group>
                                                <group
                                                    name="View07_Kiosk03"
                                                    position={[28.036, 22.527, -1.542]}
                                                    rotation={[Math.PI / 2, 0, 0.087]}
                                                    scale={0.01}
                                                >
                                                    <mesh
                                                        name="View08_Design_Optimal_Cloud_Architecture"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={
                                                            nodes.View08_Design_Optimal_Cloud_Architecture.geometry
                                                        }
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.168, 0.767, -9.064]}
                                                        rotation={[0, 0, 0.07]}
                                                        scale={0.588}
                                                    />
                                                    <group name="Kiosk_7" position={[-0.003, -0.001, 0]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_9"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_9.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[-0.003, -0.001, -9.252]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_9"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_9.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[-0.003, -0.001, 0.133]}
                                                            rotation={[0, 0, 0.07]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                </group>
                                                <group
                                                    name="View07_Kiosk04"
                                                    position={[57.223, 22.527, 5.681]}
                                                    rotation={[Math.PI / 2, 0, 0.419]}
                                                    scale={0.01}
                                                >
                                                    <group name="Kiosk_8" position={[0.004, 0, 0]}>
                                                        <mesh
                                                            name="Body_Mouseover_Off_10"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Body_Mouseover_Off_10.geometry}
                                                            material={materials['Kiosk Off Fill']}
                                                            position={[0.004, 0, -9.252]}
                                                        />
                                                        <mesh
                                                            name="Wireframe_Outline_10"
                                                            castShadow
                                                            receiveShadow
                                                            geometry={nodes.Wireframe_Outline_10.geometry}
                                                            material={materials['Wireframe Kiosks']}
                                                            position={[0.004, 0, 0.133]}
                                                            rotation={[0, 0, -0.262]}
                                                            scale={0.588}
                                                        />
                                                    </group>
                                                    <mesh
                                                        name="Migrate___Manage_Your_Cloud_Environment"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={
                                                            nodes.Migrate___Manage_Your_Cloud_Environment.geometry
                                                        }
                                                        material={materials['Flat Dark Text']}
                                                        position={[-0.364, 0.77, -9.029]}
                                                        rotation={[0, 0, -0.262]}
                                                        scale={0.588}
                                                    />
                                                </group>
                                                <group
                                                    name="View07_Video_Player_"
                                                    position={[7.526, 48.755, 17.154]}
                                                    rotation={[1.528, 0.019, -0.414]}
                                                    scale={0.01}
                                                    onClick={() => setOpen(true)}
                                                    onPointerEnter={(e) =>
                                                        onHoverOver(['Body_Mouseover_Off_6'], e.eventObject)
                                                    }
                                                    onPointerLeave={(e) =>
                                                        onHoverOut(['Body_Mouseover_Off_6'], e.eventObject, '#d4e7ed')
                                                    }
                                                >
                                                    {open && <VideoPlayer open clickAway={setOpen} />}
                                                    <mesh
                                                        name="Body_Mouseover_Off_6"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Body_Mouseover_Off_6.geometry}
                                                        // material={materials['Kiosk Off Fill']}
                                                        position={[-0.001, 0.001, 0.044]}
                                                    >
                                                        <meshStandardMaterial {...materials['Kiosk Off Fill']} />
                                                    </mesh>
                                                    <mesh
                                                        name="Play_Button_2"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Play_Button_2.geometry}
                                                        material={materials['Play Button']}
                                                        position={[0.001, 0.707, 0.044]}
                                                        rotation={[Math.PI / 2, 0, 0]}
                                                    />
                                                    <mesh
                                                        name="Wireframe_Outline_6"
                                                        castShadow
                                                        receiveShadow
                                                        geometry={nodes.Wireframe_Outline_6.geometry}
                                                        material={materials['Wireframe Kiosks']}
                                                        position={[0, 0.002, 9.561]}
                                                    />
                                                </group>
                                            </group>
                                        </group>
                                        <group name="Mini_Platform_7">
                                            <mesh
                                                name="Mesh111"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh111.geometry}
                                                material={materials['Layer02 Disc']}
                                            />
                                            <mesh
                                                name="Mesh111_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh111_1.geometry}
                                                material={materials.Layer02}
                                            />
                                        </group>
                                    </group>
                                    <group name="Business_Continuity_Risk" position={[388.735, -29.128, 0.132]}>
                                        <group
                                            name="View06_Business_Text"
                                            position={[4.21, 5.794, -73.841]}
                                            rotation={[0, 0, -1.379]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="Business_Continuity_Risk_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Business_Continuity_Risk_Mouseover_Off.geometry}
                                                material={materials['Text Off']}
                                                position={[0, 0, 7.087]}
                                            />
                                        </group>
                                        <group
                                            name="Mini_Platform_5"
                                            position={[0, 0, -0.132]}
                                            rotation={[0, 0, 1.414]}
                                            scale={1.5}
                                        >
                                            <mesh
                                                name="Mesh089"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh089.geometry}
                                                material={materials['Layer02 Disc']}
                                            />
                                            <mesh
                                                name="Mesh089_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh089_1.geometry}
                                                material={materials.Layer02}
                                            />
                                        </group>
                                    </group>
                                    <group name="Excessive_Administrative_Workload" position={[317.944, -231, 1.283]}>
                                        <group
                                            name="View06_Excessive_Text"
                                            position={[4.026, 5.542, -74.757]}
                                            rotation={[0, 0, -1.379]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="Excessive_Administrative_Workload_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={
                                                    nodes.Excessive_Administrative_Workload_Mouseover_Off.geometry
                                                }
                                                material={materials['Text Off']}
                                                position={[0.003, 0.004, 6.854]}
                                            />
                                        </group>
                                        <group
                                            name="Mini_Platform_6"
                                            position={[0, 0, -1.283]}
                                            rotation={[0, 0, 0.942]}
                                            scale={1.5}
                                        >
                                            <mesh
                                                name="Mesh092"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh092.geometry}
                                                material={materials['Layer02 Disc']}
                                            />
                                            <mesh
                                                name="Mesh092_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh092_1.geometry}
                                                material={materials.Layer02}
                                            />
                                        </group>
                                    </group>
                                    <group name="High_Cost_Legacy_Infrastrucure" position={[386.605, 225.737, 1.303]}>
                                        <group
                                            name="View06_High_Cost_Text"
                                            position={[4.474, 6.158, -74.54]}
                                            rotation={[0, 0, -1.379]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="High_Cost_Legacy_Infrastrucure_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.High_Cost_Legacy_Infrastrucure_Mouseover_Off.geometry}
                                                material={materials['Text Off']}
                                                position={[-0.003, -0.002, 6.464]}
                                            />
                                        </group>
                                        <group
                                            name="Mini_Platform_4"
                                            position={[0, 0, -1.303]}
                                            rotation={[0, 0, 1.885]}
                                            scale={1.5}
                                        >
                                            <mesh
                                                name="Mesh086"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh086.geometry}
                                                material={materials['Layer02 Disc']}
                                            />
                                            <mesh
                                                name="Mesh086_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh086_1.geometry}
                                                material={materials.Layer02}
                                            />
                                        </group>
                                    </group>
                                    <group name="Inefficient_Cloud_Design" position={[-94.575, -446.558, 0.452]}>
                                        <group
                                            name="View06_Inefficient_Text"
                                            position={[3.313, 4.56, -74.642]}
                                            rotation={[0, 0, -1.379]}
                                            scale={0.01}
                                        >
                                            <mesh
                                                name="Inefficient_Cloud_Design_Mouseover_Off"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Inefficient_Cloud_Design_Mouseover_Off.geometry}
                                                material={materials['Text Off']}
                                                position={[0.003, -0.001, 7.633]}
                                            />
                                        </group>
                                        <group name="Mini_Platform_8" position={[0, 0, -0.452]} scale={1.5}>
                                            <mesh
                                                name="Mesh114"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh114.geometry}
                                                material={materials['Layer02 Disc']}
                                            />
                                            <mesh
                                                name="Mesh114_1"
                                                castShadow
                                                receiveShadow
                                                geometry={nodes.Mesh114_1.geometry}
                                                material={materials.Layer02}
                                            />
                                        </group>
                                    </group>
                                    <group
                                        name="Mouseover_Light_4"
                                        position={[386.605, 225.737, 0]}
                                        rotation={[0, 0, -1.379]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_4"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_4.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group
                                        name="Mouseover_Light_5"
                                        position={[388.735, -29.128, 0]}
                                        rotation={[0, 0, -1.379]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_5"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_5.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group
                                        name="Mouseover_Light_6"
                                        position={[317.944, -231, 0]}
                                        rotation={[0, 0, -1.379]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_6"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_6.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group
                                        name="Mouseover_Light_7"
                                        position={[154.612, -366.684, 0]}
                                        rotation={[0, 0, -1.379]}
                                    >
                                        <mesh
                                            name="limited-hover-light"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_7.geometry}
                                            // material={materials['MO Light Off']}
                                        >
                                            <meshStandardMaterial {...materials['MO Light Off']} />
                                        </mesh>
                                    </group>
                                    <group
                                        name="Mouseover_Light_8"
                                        position={[-94.575, -446.558, 0]}
                                        rotation={[0, 0, -1.379]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_8"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_8.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                </group>
                                <mesh
                                    name="Main_Disc_2"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Main_Disc_2.geometry}
                                    material={materials.Layer02}
                                    position={[-0.007, -0.004, -3.622]}
                                    rotation={[0, 0, 1.937]}
                                />
                            </group>
                            <group
                                name="View02_Networks"
                                position={[2.908, -5.881, 101.823]}
                                rotation={[0, 0, Math.PI]}
                            >
                                <group
                                    name="Mini_Disc_Platforms_3"
                                    position={[-2.908, 5.881, -16.907]}
                                    rotation={[0, 0, 1.536]}
                                >
                                    <group
                                        name="Mouseover_Light_10"
                                        position={[277.893, -277.893, 0]}
                                        rotation={[0, 0, -1.536]}
                                    >
                                        <mesh
                                            name="Mouseover_Light_Off_10"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_10.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group name="Mouseover_Light_11" position={[393, 0, 0]} rotation={[0, 0, -1.536]}>
                                        <mesh
                                            name="Mouseover_Light_Off_11"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_11.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group name="Mouseover_Light_9" position={[0, -393, 0]} rotation={[0, 0, -1.536]}>
                                        <mesh
                                            name="Mouseover_Light_Off_9"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mouseover_Light_Off_9.geometry}
                                            material={materials['MO Light Off']}
                                        />
                                    </group>
                                    <group
                                        name="Mini_Platform_10"
                                        position={[277.893, -277.893, 0]}
                                        rotation={[0, 0, Math.PI / 4]}
                                        scale={1.5}
                                    >
                                        <mesh
                                            name="Mesh119"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh119.geometry}
                                            material={materials['Layer03 Disc']}
                                        />
                                        <mesh
                                            name="Mesh119_1"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh119_1.geometry}
                                            material={materials.Layer03}
                                        />
                                    </group>
                                    <group
                                        name="Mini_Platform_11"
                                        position={[393, 0, 0]}
                                        rotation={[0, 0, Math.PI / 2]}
                                        scale={1.5}
                                    >
                                        <mesh
                                            name="Mesh121"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh121.geometry}
                                            material={materials['Layer03 Disc']}
                                        />
                                        <mesh
                                            name="Mesh121_1"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh121_1.geometry}
                                            material={materials.Layer03}
                                        />
                                    </group>
                                    <group name="Mini_Platform_9" position={[0, -393, 0]} scale={1.5}>
                                        <mesh
                                            name="Mesh117"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh117.geometry}
                                            material={materials['Layer03 Disc']}
                                        />
                                        <mesh
                                            name="Mesh117_1"
                                            castShadow
                                            receiveShadow
                                            geometry={nodes.Mesh117_1.geometry}
                                            material={materials.Layer03}
                                        />
                                    </group>
                                </group>
                                <mesh
                                    name="Main_Disc_3"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.Main_Disc_3.geometry}
                                    material={materials.Layer03}
                                    position={[-0.007, -0.004, -1.276]}
                                    rotation={[0, 0, 1.937]}
                                />
                            </group>
                        </group>
                        <group name="Main_Text" position={[573.71, -17.576, -32.881]}>
                            <group
                                name="Apps__Data___AI"
                                position={[-9.476, 0, 380.872]}
                                onClick={() => setView(2)}
                                onPointerEnter={(event) =>
                                    onHoverOver(['View02_Apps__Data__Text__AI', 'View02_Line_2'], event.eventObject)
                                }
                                onPointerLeave={(event) =>
                                    onHoverOut(['View02_Apps__Data__Text__AI', 'View02_Line_2'], event.eventObject)
                                }
                            >
                                <mesh
                                    name="View02_Apps__Data__Text__AI"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Apps__Data__Text__AI.geometry}
                                    // material={materials['Flat Grey']}
                                    position={[1064.324, 0, 16.754]}
                                >
                                    <meshStandardMaterial {...materials['Flat Grey']} />
                                </mesh>
                                <mesh
                                    name="View02_Line_2"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Line_2.geometry}
                                    material={materials['Flat Grey']}
                                    position={[138.126, 0, -16.523]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    scale={[0.01, 1, 1]}
                                >
                                    <meshStandardMaterial {...materials['Flat Grey']} />
                                </mesh>
                                <mesh
                                    name="click_target_appsdata"
                                    castShadow
                                    receiveShadow
                                    visible={false}
                                    geometry={nodes.click_target_appsdata.geometry}
                                    material={nodes.click_target_appsdata.material}
                                    position={[487.596, 0.843, -8.466]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                />
                            </group>
                            <group name="Business_Strategy_2">
                                <mesh
                                    name="View02_Business_Strategy_Text"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Business_Strategy_Text.geometry}
                                    material={materials['Flat Blue']}
                                    position={[1094.996, 0, 16.523]}
                                />
                                <mesh
                                    name="View02_Line_1"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Line_1.geometry}
                                    material={materials['Flat Blue']}
                                    position={[138.126, 0, -16.523]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    scale={[0.01, 1, 1]}
                                />
                            </group>
                            <group name="Devices" position={[-31.846, 0, 1659.245]}>
                                <mesh
                                    name="View02_Devices_Text"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Devices_Text.geometry}
                                    material={materials['Flat Grey']}
                                    position={[903.092, 0, 16.523]}
                                />
                                <mesh
                                    name="View02_Line_5"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Line_5.geometry}
                                    material={materials['Flat Grey']}
                                    position={[138.126, 0, -16.523]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    scale={[0.01, 1, 1]}
                                />
                                <mesh
                                    name="click_target_networks_2"
                                    castShadow
                                    receiveShadow
                                    visible={false}
                                    geometry={nodes.click_target_networks_2.geometry}
                                    material={nodes.click_target_networks_2.material}
                                    position={[343.697, 0.843, -15.409]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                />
                            </group>
                            <group
                                name="Infrastructure"
                                position={[-17.751, 0, 782.574]}
                                onClick={() => setView(5)}
                                onPointerEnter={(event) =>
                                    onHoverOver(['View02_Infrastructure_Text', 'View02_Line_3'], event.eventObject)
                                }
                                onPointerLeave={(event) =>
                                    onHoverOut(['View02_Infrastructure_Text', 'View02_Line_3'], event.eventObject)
                                }
                            >
                                <mesh
                                    name="View02_Infrastructure_Text"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Infrastructure_Text.geometry}
                                    material={materials['Flat Grey']}
                                    position={[1012.698, 0, 17.212]}
                                >
                                    <meshStandardMaterial {...materials['Flat Grey']} />
                                </mesh>
                                <mesh
                                    name="View02_Line_3"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Line_3.geometry}
                                    material={materials['Flat Grey']}
                                    position={[138.126, 0, -16.523]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    scale={[0.01, 1, 1]}
                                >
                                    <meshStandardMaterial {...materials['Flat Grey']} />
                                </mesh>
                                <mesh
                                    name="click_target_infrastructure"
                                    castShadow
                                    receiveShadow
                                    visible={false}
                                    geometry={nodes.click_target_infrastructure.geometry}
                                    material={nodes.click_target_infrastructure.material}
                                    position={[457.105, 0.843, -13.638]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                />
                            </group>
                            <group name="Networks" position={[-22.342, 0, 1201.424]}>
                                <mesh
                                    name="View02_Line_4"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Line_4.geometry}
                                    material={materials['Flat Grey']}
                                    position={[138.126, 0, -16.523]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    scale={[0.01, 1, 1]}
                                />
                                <mesh
                                    name="View02_Networks_Text"
                                    castShadow
                                    receiveShadow
                                    geometry={nodes.View02_Networks_Text.geometry}
                                    material={materials['Flat Grey']}
                                    position={[944.457, 0, 17.459]}
                                />
                                <mesh
                                    name="click_target_networks"
                                    castShadow
                                    receiveShadow
                                    visible={false}
                                    geometry={nodes.click_target_networks.geometry}
                                    material={nodes.click_target_networks.material}
                                    position={[382.674, 0.843, -11.849]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                />
                            </group>
                        </group>
                        <group name="Structure_Body" position={[-26.605, -23.461, 1997.033]}>
                            <mesh
                                name="Base"
                                castShadow
                                receiveShadow
                                geometry={nodes.Base.geometry}
                                material={materials['C Aluminum Brushed 03']}
                                position={[0, 0, -11.714]}
                            />
                            <mesh
                                name="Main_Body"
                                castShadow
                                receiveShadow
                                geometry={nodes.Main_Body.geometry}
                                // material={materials['Plastic Glossy 001 White']}
                                position={[0, 0, -759.745]}
                            >
                                <meshPhysicalMaterial
                                    {...materials['Plastic Glossy 001 White']}
                                    metalness={1}
                                    clearcoat={1}
                                    reflectivity={1}
                                    roughness={0.65}
                                    emissive="#555"
                                />
                            </mesh>
                            <mesh
                                name="Top"
                                castShadow
                                receiveShadow
                                geometry={nodes.Top.geometry}
                                material={materials['C Aluminum Brushed 03']}
                                position={[0, 0, -1976.992]}
                            />
                        </group>
                    </group>
                    <mesh
                        name="Structure_Ground_Plane"
                        castShadow
                        // receiveShadow
                        geometry={nodes.Structure_Ground_Plane.geometry}
                        material={materials.Ground}
                        position={[-72.065, 0, 1991.942]}
                    />
                </group>
            </group>
        </group>
    )
})

useGLTF.preload('/Demo01_Animation_v7b.glb')
