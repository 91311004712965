import { PerspectiveCamera } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { forwardRef, useEffect, useRef } from 'react'

const Camera = forwardRef((props, ref) => {
    const set = useThree((state) => state.set)
    useEffect(() => void set({ camera: ref.current }), [])
    useFrame(() => ref.current.updateMatrixWorld())
    return <PerspectiveCamera ref={ref} {...props} />
})

export default Camera
