import { Html } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import '../react-player.css'

export default function ({ open, clickAway }) {
    const player = useRef()
    const [playing, setPlaying] = useState(false)
    const onReady = (e) => {
        document.addEventListener('click', (event) => {
            if (player.current && !player.current.contains(event.target)) {
                clickAway(false)
            }
        })
    }
    useEffect(() => {
        if (open) {
            setPlaying(true)
        }
    })
    return (
        <Html fullscreen>
            <div className="video">
                <div ref={player} className="player-wrapper">
                    <ReactPlayer
                        className="player"
                        playing={playing}
                        onReady={onReady}
                        controls
                        url="migrating_to_the_public_cloud.mp4"
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        </Html>
    )
}
