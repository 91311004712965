import { Html, OrbitControls, SoftShadows, useAnimations, useGLTF } from '@react-three/drei'
// import { Perf } from 'r3f-perf'
import { Model } from './Demo01_Animation_v7b.jsx'
import Camera from './Camera.jsx'
import useTransitions from './hooks/useTransitions.js'
import { useLayoutEffect, useRef, useState } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import gsap from 'gsap/all'
import { Color, Vector3 } from 'three'
import ViewTitle from './components/ViewTitle.jsx'
// import { useControls } from 'leva'

const durations = [3.5, 2.33, 1, 1.03, 1, 1, 1.03, 1]
export default function Experience() {
    const group = useRef()
    const camera = useRef()
    const [text, setText] = useState(0)
    // const { rotation } = useControls({
    //     rotation: { value: { x: -1.49, y: 0.08, z: 0.7 }, step: 0.01 },
    // })
    const siloedText = 'View03_Siloed_Text'
    const limitedText = 'View06_Limited_Scale_Text'
    const { nodes, materials, animations } = useGLTF('/Demo01_Animation_v7b.glb')
    const { scene } = useThree()
    const camRotations = [
        new Vector3(0.006, 0.041, 0.011),
        new Vector3(9.195, -8.295, 0.011),
        new Vector3(2.992, -3.012, 2.83),
        new Vector3(4.006, -2.785, 0.038),
        new Vector3(3.561, -2.728, 0.308),
        new Vector3(2.992, -7.131, 2.83),
        new Vector3(3.884, -7.002, 0.785),
        new Vector3(4.18, -6.873, 0.785),
    ]
    const [target, setTarget] = useState(camRotations[0])
    const { actions, names } = useAnimations(animations, group)
    const [currentView, doTransition] = useTransitions(actions, names, durations)
    const cameraSettings = [
        { position: [-2.897, 8.019, 5.827], rotation: [-0.93, -0.27, -0.32] },
        { position: [9.274, 2.972, 39.441], rotation: [-0.27, -0.04, -0.01] },
        { position: [8.951, -0.937, 8.607], rotation: [-0.35, 0.77, 0.24] },
        { position: [4.604, -2.373, 1.423], rotation: [-0.27, 0.39, 0.1] },
        // above here done
        { position: [3.72, -2.719, 0.38], rotation: [1.293, 0, -0.001] },
        { position: [8.951, -5.048, 8.607], rotation: [1.397, 0.176, -0.785] },
        { position: [4.805, -6.524, 2.236], rotation: [1.342, 0.143, -0.548] },
    ]
    useLayoutEffect(() => {
        if (!cameraSettings[currentView]) return
        let { position, rotation } = cameraSettings[currentView]

        gsap.to(camera.current.position, {
            x: position[0],
            y: position[1],
            z: position[2],
            duration: durations[currentView],
        })
        let targetRotation = new Vector3(...target)
        gsap.to(targetRotation, {
            ...camRotations[currentView],
            duration: durations[currentView],
            onUpdate: () => {
                setTarget(targetRotation)
            },
        })

        const siloedEl = scene.getObjectByName(siloedText)
        const limitedEl = scene.getObjectByName(limitedText)
        if (currentView != 3 && currentView != 4) {
            siloedEl.visible = true
        } else {
            siloedEl.visible = false
        }
        if (currentView != 6) {
            limitedEl.visible = true
        } else {
            limitedEl.visible = false
        }
    }, [currentView])

    useFrame((_, delta) => {
        camera.current.lookAt(target)
    })

    return (
        <>
            {/* <Perf position="top-left" /> */}
            <Camera
                ref={camera}
                makeDefault
                far={1000}
                near={0.1}
                fov={35}
                aspect={16 / 9}
                position={[1.2, 16.113, 1.219]}
                rotation={[-1.494, 0.08, 0.69]}
            />
            <directionalLight position={[1, 5, 3]} intensity={5} />
            {/* <OrbitControls makeDefault /> */}

            {/* <SoftShadows size={2} samples={6} focus={0} /> */}

            {/* <ambientLight intensity={1.5} />
            <directionalLight
                castShadow
                shadow-bias={-0.002}
                shadow-radius={10}
                shadow-blurSamples={25}
                position={[1.7, 4, 3]}
                intensity={2.5}
            >
                <orthographicCamera attach="shadow-camera" args={[-10, 10, -20, 20, 0.1, 100]} />
            </directionalLight> */}

            {/* <ambientLight intensity={2} /> */}
            {/* <directionalLight
                castShadow
                shadow-bias={-0.002}
                shadow-radius={10}
                shadow-blurSamples={25}
                position={[1.7, 5, 3]}
                intensity={2.5}
            >
                <orthographicCamera attach="shadow-camera" args={[-10, 10, -20, 20, 0.1, 100]} />
            </directionalLight> */}

            <Model ref={group} />
            <Html as="div" portal={document.body} wrapperClass="ui-el" transform={false} center={false}>
                <div className="inner">
                    {currentView == 0 && (
                        <div className="box" onClick={() => doTransition(1)}>
                            How do you execute your business strategy through technology?
                        </div>
                    )}
                    {currentView == 1 && text < 2 && (
                        <div className="flex-col">
                            <div className="box middle flex-col">
                                {/* {text == 0 && ( */}
                                <div className={`text ${text == 0 && 'visible'}`}>
                                    Your business strategy is enabled by a technology ecosystem that can improve
                                    productivity and create a competitive advantage.
                                </div>
                                {/* )} */}
                                {/* {text == 1 && ( */}
                                <div className={`text ${text == 1 && 'visible'}`}>
                                    Many growing businesses struggle with legacy systems and technical debt, which can
                                    impede results and increase costs.
                                </div>
                                <div className="icons">
                                    <img
                                        onClick={() => text > 0 && setText(text - 1)}
                                        className="left"
                                        src="icons/Button-Arrow.png"
                                        alt="arrow button"
                                    />
                                    <img
                                        onClick={() => text < 3 && setText(text + 1)}
                                        src="icons/Button-Arrow.png"
                                        alt="arrow button"
                                    />
                                </div>
                                {/* )} */}
                            </div>
                        </div>
                    )}
                    {currentView == 1 && text == 2 && (
                        <div className="multi-box flex-col middle">
                            <div onClick={() => doTransition(2)} className="box">
                                Modernize applications and unleash the power of AI.
                            </div>
                            <div onClick={() => doTransition(5)} className="box">
                                Migrate to the cloud to achieve scalability, savings, and enhanced security.
                            </div>
                            <div className="box">
                                Implement security measures across the extended enterprise network.
                            </div>
                            <div className="box">Enable the modern workforce with productivity at the edge.</div>
                        </div>
                    )}
                    {(currentView == 2 || currentView == 3 || currentView == 6) && (
                        <div className="box center bottom" onClick={() => doTransition(currentView, true)}>
                            Back
                        </div>
                    )}
                    {currentView == 5 && (
                        <div className="box center bottom" onClick={() => doTransition(2, true)}>
                            Back
                        </div>
                    )}
                </div>
            </Html>
            {currentView == 4 && (
                <Html wrapperClass="ui-screen" className="container">
                    <div className="box top center">Use Case & Prototyping</div>
                    <div className="content">
                        <p>Text, Image, Video Extraction and Analysis </p>
                        <p>AI Use Case Identification and Design</p>
                        <p>Build Large Language Model, Custom ML and Deep Learning</p>
                        <p>Proof of Concept Testing and Validation</p>
                    </div>
                    <div className="buttons">
                        <div
                            className="box left"
                            onClick={() => {
                                document.querySelector('.ui-screen').classList.remove('visible')
                                doTransition(currentView, true)
                            }}
                        >
                            Back
                        </div>
                        <div className="box button">
                            To engage with an AI/ML expert - <b> CLICK HERE</b>
                        </div>
                    </div>
                </Html>
            )}
            {(currentView == 3 || currentView == 6) && (
                <Html wrapperClass="panda">
                    <div className="container">
                        <div className="challenge">
                            <span className="challenge-title">CHALLENGE:</span>
                            {currentView == 3 && (
                                <span className="challenge-text">Siloed data constraining innovation</span>
                            )}
                            {currentView == 6 && <span className="challenge-text">Limited Scale and Flexibility</span>}
                        </div>
                        <div className="solution">
                            <span className="solution-title">SOLUTION:</span>
                            {currentView == 3 && (
                                <span className="solution-text">Data strategy and AI/ML integration</span>
                            )}
                            {currentView == 6 && <span className="solution-text"> Cloud Migration Services</span>}
                        </div>
                    </div>
                </Html>
            )}
            <ViewTitle />
        </>
    )
}
