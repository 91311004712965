import { Html } from '@react-three/drei'
import { useViewStore } from '../store/useViewStore'

export default function () {
    const { view, setView } = useViewStore()
    return (
        <Html wrapperClass={`title${view == 2 || view == 5 ? ' visible' : ''}`}>
            <div className="title-container">{view == 2 && <div className="box">Apps, Data & AI</div>}</div>
            <div className="title-container">{view == 5 && <div className="box">Infrastructure</div>}</div>
        </Html>
    )
}
