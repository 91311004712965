import gsap from 'gsap'
import { Color } from 'three'

const duration = 0.2
export const onHoverOver = (names, container, color = '#0decba') => {
    names.forEach((name) => {
        gsap.to(container.getObjectByName(name).material.color, {
            ...new Color(color),
            duration: duration,
        })
    })
}
export const onHoverOut = (names, container, color = 'black') => {
    names.forEach((name) => {
        gsap.to(container.getObjectByName(name).material.color, {
            ...new Color(color),
            duration: duration,
        })
    })
}
